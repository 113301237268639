<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="7d"
            label="작업기간"
            name="workDts"
            v-model="searchParam.workDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="일일작업계획 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <!-- :clickable="true"
      @click="openDetail(props.row)" -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='workEndFlag'">
          <q-chip
            :color="getColors(props.row.workEndFlag)"
            text-color="white">
            {{props.row.workEndFlagName}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  data() {
    return {
      searchParam: {
        plantCd: null,
        workDts: [],
        workMonths: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'workDt' },
          { index: 1, colName: 'workDt' },
        ],
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'workDt',
            field: 'workDt',
            label: '작업시작일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'workResultName',
            field: 'workResultName',
            label: '정비오더명(정비오더 link)',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'link'
          },
          {
            name: 'workEndFlag',
            field: 'workEndFlag',
            label: '작업결과',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'workResultWorkName',
            field: 'workResultWorkName',
            label: '작업계획명(일일작업계획 link)',
            align: 'left',
            style: 'width:250px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '작업부서',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'workContent',
            field: 'workContent',
            label: '작업내역',
            align: 'left',
            sortable: true,
          },
          {
            name: 'workpermitTypesName',
            field: 'workpermitTypesName',
            label: '작업허가서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'workRiskFactorsName',
            field: 'workRiskFactorsName',
            label: '위험요인',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workorder.daily.list.url;

      // 날짜 초기화-시연용
      this.searchParam.workDts = []
      
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      if (col.name === 'workResultWorkName') {
        this.openDetail(row);
      } else {
        this.openPop(row);
      }
    },
    openPop(row) {
      this.popupOptions.title = '정비오더 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: row ? row.woRequestId : '',
        workPlanFlag: true,
      };
      this.popupOptions.target = () => import(`${'./workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WDRC000010': // 작업완료
          returnColor = 'blue';
          break;
        case 'WDRC000009': // 작업취소
          returnColor = 'red';
          break;
        case 'WDRC000001': // 작업결과입력
          returnColor = 'orange';
          break;
        case 'WDRC000002': // 작업결과입력
          returnColor = 'green';
          break;
      }

      return returnColor;
    },
    openDetail(row) {
      this.popupOptions.title = '일일작업 결과';
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.param = {
        workResultWorkId: row ? row.workResultWorkId : '',
        workDt: row ? row.workDt : '',
        workResultDailyId: row ? row.workResultDailyId : '',
      };
      this.popupOptions.target = () => import(`${'./workOrderDailyPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    }
  }
};
</script>
